import { BaseLink } from "@clipboard-health/ui-react";
import { Badge, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useIsJobsEnabled } from "@src/appV2/PlacementCandidate/useIsJobsEnabled";
import { type ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { MyAccountIcon, MyShiftsIcon, OpenShiftsIcon, RatingsIcon } from "./assets";

interface AppTabBarProps {
  notificationsCount: number;
}

export function AppTabBar(props: AppTabBarProps): ReactElement {
  const { notificationsCount } = props;
  const location = useLocation();
  const isJobsEnabled = useIsJobsEnabled();

  return (
    <BottomNavigation
      showLabels
      value={location.pathname}
      sx={{
        /**
         * TODO work with design team to decide on whether we need this border
         */
        borderTop: "1px solid",
        borderTopColor: (theme) => theme.palette.divider,
      }}
    >
      <BottomNavigationAction
        component={BaseLink}
        to={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS}
        value={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS}
        icon={<OpenShiftsIcon />}
        label={isJobsEnabled ? "Find Work" : "Find Shifts"}
      />
      <BottomNavigationAction
        component={BaseLink}
        to={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
        value={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
        icon={<MyShiftsIcon />}
        label="My Shifts"
      />
      <BottomNavigationAction
        component={BaseLink}
        to={DeprecatedGlobalAppV1Paths.RATINGS}
        value={DeprecatedGlobalAppV1Paths.RATINGS}
        icon={<RatingsIcon />}
        label="Status"
      />
      <BottomNavigationAction
        component={BaseLink}
        to={DeprecatedGlobalAppV1Paths.ACCOUNT}
        value={DeprecatedGlobalAppV1Paths.ACCOUNT}
        aria-label="My Account"
        icon={
          <Badge
            badgeContent={
              location.pathname === DeprecatedGlobalAppV1Paths.ACCOUNT ? 0 : notificationsCount
            }
            color="error"
            role="status"
            aria-label="Account Notifications Count"
          >
            <MyAccountIcon />
          </Badge>
        }
        label="My Account"
      />
    </BottomNavigation>
  );
}
