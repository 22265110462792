import { SvgIcon, type SvgIconProps } from "@mui/material";

export function MyShiftsIcon(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="small" {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 37 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        version="1.1"
      >
        <g transform="translate(-467.942,-213.239)" id="g15128">
          <g id="My-Shifts---Off" transform="translate(42.2796,-50.0841)">
            <g transform="translate(-42.2796,50.0841)" id="g15117" />
            <g transform="translate(-42.2796,-70.6425)" id="g15121" />
            <g transform="translate(-42.2796,50.0841)" id="g15125">
              <path
                d="m 477.942,216.239 c 0,-1.656 1.344,-3 3,-3 h 12 c 1.656,0 3,1.344 3,3 h 2 c 3.864,0 7,3.137 7,7 0,0 0,33 0,33 0,3.863 -3.136,7 -7,7 0,0 -23,0 -23,0 -3.863,0 -7,-3.137 -7,-7 v -33 c 0,-3.863 3.137,-7 7,-7 z m 18,3 c 0,1.656 -1.344,3 -3,3 h -12 c -1.656,0 -3,-1.344 -3,-3 h -3 c -2.207,0 -4,1.792 -4,4 0,0 0,33 0,33 0,2.208 1.793,4 4,4 h 23 c 2.208,0 4,-1.792 4,-4 v -33 c 0,-2.208 -1.792,-4 -4,-4 z m -17,35 h 17 c 1.104,0 2,-0.896 2,-2 0,-1.104 -0.896,-2 -2,-2 h -17 c -1.104,0 -2,0.896 -2,2 0,1.104 0.896,2 2,2 z m 0,-8 h 16 c 1.104,0 2,-0.896 2,-2 0,-1.104 -0.896,-2 -2,-2 h -16 c -1.104,0 -2,0.896 -2,2 0,1.104 0.896,2 2,2 z m 0,-8 h 17 c 1.104,0 2,-0.896 2,-2 0,-1.104 -0.896,-2 -2,-2 h -17 c -1.104,0 -2,0.896 -2,2 0,1.104 0.896,2 2,2 z m 0,-8 h 12 c 1.104,0 2,-0.896 2,-2 0,-1.104 -0.896,-2 -2,-2 h -12 c -1.104,0 -2,0.896 -2,2 0,1.104 0.896,2 2,2 z"
                fill="currentColor"
                id="path15123"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
