import { SvgIcon, type SvgIconProps } from "@mui/material";

export function MyAccountIcon(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="small" {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        version="1.1"
      >
        <g transform="matrix(1,0,0,1,-561.534,-240.052)">
          <g id="My-Account" transform="matrix(1,0,0,1,0,-33.9156)">
            <g transform="matrix(1,0,0,1,551.575,261.961)">
              <path
                d="M14.856,42.071L35.127,42.071C38.364,42.071 40.024,40.411 40.024,37.224L40.024,16.87C40.024,13.666 38.364,12.006 35.127,12.006L14.856,12.006C11.619,12.006 9.959,13.649 9.959,16.87L9.959,37.224C9.959,40.428 11.619,42.071 14.856,42.071ZM25,32.376C19.007,32.376 14.84,35.165 12.864,39.382C12.3,38.9 12.018,38.137 12.018,37.107L12.018,16.97C12.018,15.06 13.03,14.065 14.89,14.065L35.077,14.065C36.92,14.065 37.966,15.06 37.966,16.97L37.966,37.107C37.966,38.137 37.667,38.9 37.103,39.382C35.16,35.148 31.093,32.376 25,32.376ZM25,29.637C28.138,29.67 30.611,26.981 30.611,23.461C30.611,20.157 28.138,17.435 25,17.435C21.846,17.435 19.356,20.157 19.372,23.461C19.405,26.981 21.846,29.587 25,29.637Z"
                fill="currentColor"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(1,0,0,1,0,33.9156)">
              <path
                d="M576.575,260.422C570.582,260.422 566.415,263.211 564.44,267.427C563.875,266.946 563.593,266.182 563.593,265.153L563.593,245.015C563.593,243.106 564.606,242.11 566.465,242.11L586.652,242.11C588.495,242.11 589.541,243.106 589.541,245.015L589.541,265.153C589.541,266.182 589.242,266.946 588.678,267.427C586.736,263.194 582.668,260.422 576.575,260.422ZM576.575,257.682C579.713,257.716 582.187,255.026 582.187,251.507C582.187,248.203 579.713,245.48 576.575,245.48C573.421,245.48 570.931,248.203 570.947,251.507C570.981,255.026 573.421,257.633 576.575,257.682Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
