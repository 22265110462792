import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AccountReactivationRoutes } from "../AccountReactivation";
import { ACCOUNT_REACTIVATION_PATH } from "../AccountReactivation/paths";
import { AUTH_PATH, IonLoginSignUpRoutes } from "../Auth";
import { E2E_TEST_PATH } from "../E2E/paths";
import { E2ERoutes } from "../E2E/Routes";
import { useLegacyStateContext } from "../LegacyStateBridge/context";
import { NotFoundPage } from "../lib";
import { AppLoadingPage } from "../lib/Layouts/LoadingPage/Page";

export function AppV2PublicRoutes(): ReactElement {
  const match = useRouteMatch();

  const {
    session: { authStateHasLoaded },
  } = useLegacyStateContext();
  if (!authStateHasLoaded) {
    return <AppLoadingPage />;
  }

  return (
    <Switch>
      <Route path={`${match.url}/${AUTH_PATH}`}>
        <IonLoginSignUpRoutes />
      </Route>
      <Route path={`${match.url}/${ACCOUNT_REACTIVATION_PATH}`}>
        <AccountReactivationRoutes />
      </Route>
      <Route path={`${match.url}/${E2E_TEST_PATH}`}>
        <E2ERoutes />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
