import { SvgIcon, type SvgIconProps } from "@mui/material";

export function RatingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="small" {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 22.747936 21.743317"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        version="1.1"
        id="svg3280"
      >
        <defs id="defs3277" />
        <g id="layer1" transform="translate(-112.8459,-157.91217)">
          <path
            d="m 117.20477,179.4483 q 0.36174,0.2713 0.80098,0.18733 0.43926,-0.084 0.95601,-0.47155 l 5.25807,-3.86281 5.271,3.86281 q 0.51676,0.38757 0.95602,0.47155 0.43926,0.084 0.78806,-0.18733 0.34883,-0.2713 0.41341,-0.70409 0.0646,-0.43279 -0.1421,-1.03999 l -2.07997,-6.18824 5.30973,-3.81114 q 0.51679,-0.36174 0.72994,-0.75578 0.21317,-0.39402 0.0711,-0.82037 -0.1421,-0.41341 -0.53615,-0.60719 -0.39402,-0.19378 -1.03997,-0.19378 l -6.51124,0.0517 -1.97662,-6.22702 q -0.19378,-0.60719 -0.50384,-0.92371 -0.31007,-0.31652 -0.74933,-0.31652 -0.43924,0 -0.74285,0.31652 -0.30358,0.31652 -0.5103,0.92371 l -1.97662,6.22702 -6.51124,-0.0517 q -0.63301,0 -1.03351,0.19378 -0.4005,0.19378 -0.54261,0.60719 -0.14211,0.42635 0.0711,0.82037 0.21316,0.39404 0.72994,0.75578 l 5.30974,3.81114 -2.07997,6.18824 q -0.20672,0.6072 -0.14211,1.03999 0.0646,0.43279 0.41341,0.70409 z"
            id="path117"
            fill="currentColor"
          />
        </g>{" "}
      </svg>
    </SvgIcon>
  );
}
